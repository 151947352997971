import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleYearSlider from '../components/articleYearSlider'
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Tween, Timeline } from 'react-gsap';
import { Container, Row, Col } from 'react-bootstrap';
import { Controller, Scene } from 'react-scrollmagic';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// import { useStaticQuery, graphql } from "gatsby"

console.log(121);
export const SINGLE_ARTICLE_QUERY = gql`
    query singleArticleData($postId: ID!, $postYear: String!) {
        post(id: $postId) {
            is_featured
            status
            title
            uri
            frontline_articles_options {
                articleYear
                fullDescriptionAboveGallery
                fullDescriptionBelowGallery
                yearSizeOverTheTimeline
                yearPositionWithTheTimeline
                yearPositionWithTheImage
                shortDescription
                imagePositionOverTimeline
                articlePageBackgroundColor
                articlePageGalleryTitleColor
                articlePageTextColor
                articlePageTimelineColor
                backgroundImage {
                    sourceUrl
                }
                backgroundColor
                featuredImage {
                    sourceUrl
                }
                articleTemplate
                belowTitleShortDescription
                enableGallery
                galleryPageDescription
                enableExploreSection
                exploreSectionBackgroundColor
                exploreSectionTitleColor
                setBackgroundImageAsFeaturedImage
            }
            id
            frontline_article_gallery_options {
                mediaType {
                    isFeatured
                    imageVideoDocumentTitle
                    imageVideoDocumentCaption
                    galleryVideoId
                    mediaType
                    galleryImage {
                        sourceUrl
                        id
                    }
                    documentUpload {
                        mediaItemUrl
                        id
                    }
                    galleryVideoPoster {
                        sourceUrl
                        id
                    }
                    documentPoster {
                        sourceUrl
                        id
                    }
                    galleryVideoType
                }
            }
        }
        posts(first: 100, where: {metaQuery: {metaArray: {key: "article_year", value: $postYear}}}) {
            edges {
              node {
                id
                uri
                status
                title
                is_featured
                frontline_articles_options {
                  articleYear
                  shortDescription
                  backgroundImage {
                    sourceUrl
                  }
                  featuredImage {
                    sourceUrl
                  }
                  articleTemplate
                }
              }
            }
        }
    }
`;

const SingleArticle = ({ pageContext }) => {
	let postId = pageContext.id;
	let postYear = pageContext.articleYear;

	const { data, loading, error } = useQuery(
		SINGLE_ARTICLE_QUERY,
		{ variables: { postId, postYear } }
	);

	useEffect(() => {
		// Article Video
		setTimeout(function () {
			if ($(".article-videoLink").length > 0) {
				$('.article-videoLink').click(function (e) {
					e.preventDefault();
					e.stopPropagation();
					var _videow = $(".embed-responsive").width();
					var _videoh = $(".embed-responsive").height();
					var video = '<div class="embed-responsive embed-responsive-16by9"><iframe id="modal-video" src="' + $(this).attr('data-video') + '" height="' + _videoh + '" width="' + _videow + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe></div>';
					$(this).parent('.embed-responsive').replaceWith(video);
					return false;
				});
			}
		}, 2500);
		// if (typeof window !== `undefined`) {
		setTimeout(function () {
			$('html, body').animate({ scrollTop: 0 }, 1000, function () {
				// Sticky Sidebar
				if (window.matchMedia("(min-width: 992px)").matches) {
					// var SingleArticleOffsetTop;
					// var SingleArticleYearTimelineOffsetTop = $(".single-article-year-timeline").offset().top;
					if ($(".single-article-year-timeline").length) {
						if ($(".article-timeline-verticle .swiper-container").length) {
							var SingleArticleYearTimelineOffsetLeft = $(".single-article-year-timeline").offset().left;
							var windowHeight = $(window).height();
							var headerHeight = $('#site-header').height();
							$('.article-timeline-verticle .swiper-container').height(windowHeight - headerHeight - 40);

							$(".article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
							$(".article-timeline-verticle").css("position", "fixed");

							var SingleArticleYearTimelineContainerOffsetTop = $(".article-timeline-verticle .swiper-container").offset().top;
							var SingleArticleYearTimelineContainerHeight = $(".article-timeline-verticle .swiper-container").height();
							var SingleArticleYearTimelineContainerOffsetBottom = SingleArticleYearTimelineContainerOffsetTop + SingleArticleYearTimelineContainerHeight;

							// console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

							var SingleArticleCoverOffsetTop = $(".single-article-cover").offset().top;
							var SingleArticleCoverHeight = $(".single-article-cover").height();
							var SingleArticleCoverOffsetBottom = SingleArticleCoverOffsetTop + SingleArticleCoverHeight;

							// console.log(SingleArticleCoverOffsetBottom + " SingleArticleCoverOffsetBottom");

							$(window).scroll(function () {
								if ($(".single-article-wrap").length) {
									var windowScroll = $(this).scrollTop();
									// SingleArticleOffsetTop = $(".single-article-wrap").offset().top;
									var SingleArticleOffsetBottomScroll = SingleArticleCoverOffsetBottom - windowScroll;
									// console.log(SingleArticleOffsetBottomScroll + " SingleArticleOffsetBottomScroll");
									// console.log(SingleArticleYearTimelineContainerOffsetBottom + " SingleArticleYearTimelineContainerOffsetBottom");

									if (SingleArticleOffsetBottomScroll <= SingleArticleYearTimelineContainerOffsetBottom) {
										// console.log("hii");
										$(".single-article-cover-wrap .article-timeline-verticle").css("position", "absolute");
										$(".single-article-cover-wrap .article-timeline-verticle").css("bottom", 60);
										$(".single-article-cover-wrap .article-timeline-verticle").css("left", 0);
									} else {
										// console.log("else");
										$(".single-article-cover-wrap .article-timeline-verticle").removeAttr("style");
										$(".single-article-cover-wrap .article-timeline-verticle").css("left", SingleArticleYearTimelineOffsetLeft);
										$(".single-article-cover-wrap .article-timeline-verticle").css("position", "fixed");
									}
								}
							});
						}
					}
				}
			});

		}, 1500);
		// }
	}, []);

	const params_explore = {
		spaceBetween: 15,
		slidesPerView: 'auto',
		freeMode: true,
		freeModeMomentum: false,
		freeModeMomentumBounce: false,
		simulateTouch: true,
		mousewheel: {
			releaseOnEdges: true,
		},
		breakpoints: {
			992: {
				spaceBetween: 40
			}
		},
		on: {
			beforeInit: function () {
				var containerOffsetLeft = $(".single-article-cover > .container .single-article-timeline").offset().left;
				this.prependSlide('<div class="swiper-slide"></div>');
				$(".explore-slider .swiper-slide:first-child").width(containerOffsetLeft - params_explore.spaceBetween);
			},
			resize: function () {
				var containerOffsetLeft = $(".single-article-cover > .container .single-article-timeline").offset().left;
				$(".explore-slider .swiper-slide:first-child").width(containerOffsetLeft - params_explore.spaceBetween);
			}
		}
	}

	if (loading) return null;
	if (error) return <p>ERROR: {error.message}</p>;

	// console.log(data);
	let galleryItemArr = data.post.frontline_article_gallery_options.mediaType;
	let isGalleryOnBckImage = 1;
	const articleGallery = data.post.frontline_article_gallery_options.mediaType !== null && data.post.frontline_article_gallery_options.mediaType.map((mediaTypes, index) => {
		if (mediaTypes.isFeatured === true) {
			isGalleryOnBckImage = 2;
		}
		return mediaTypes.isFeatured === true && <div className="article_gallery_wrap" key={index}>

			{mediaTypes.mediaType === "image" && <div className="article_gallery_sec">
				{data.post.frontline_articles_options.enableGallery != true && mediaTypes.imageVideoDocumentTitle !== null && <h4 className="article_gallery_title"><span>GALLERY: </span>{mediaTypes.imageVideoDocumentTitle}</h4>}
				{mediaTypes.galleryImage !== null && <div className="article_gallery_link_img">
					<img src={mediaTypes.galleryImage.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />
					{data.post.frontline_articles_options.enableGallery === true && galleryItemArr.length > 1 && <AniLink className="btn primary-btn" fade duration={2} to={"/" + data.post.uri + "/gallery"}>VIEW GALLERY</AniLink>}
				</div>}
				{galleryItemArr.length <= 1 && mediaTypes.imageVideoDocumentCaption != null && <div className="article_gallery_caption"><span>Source: </span>{mediaTypes.imageVideoDocumentCaption}</div>}
			</div>}

			{mediaTypes.mediaType === "video" && <div className="article_video_sec">
				{data.post.frontline_articles_options.enableGallery != true && mediaTypes.imageVideoDocumentTitle != null && <h4 className="article_video_title"><span>WATCH: </span>{mediaTypes.imageVideoDocumentTitle}</h4>}
				{mediaTypes.galleryVideoPoster != null && <div className="article_video_link_img">
					{/* <img src={mediaTypes.galleryVideoPoster} alt={mediaTypes.video_title} /> */}
					<div className="embed-responsive embed-responsive-16by9">
						<img className="img-fluid" src={mediaTypes.galleryVideoPoster.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />
						{mediaTypes.galleryVideoType === "vimeo" && <a href="#!" className="article-videoLink" data-video={"https://player.vimeo.com/video/" + mediaTypes.galleryVideoId + "?autoplay=true"}>PLAY VIDEO</a>}
						{mediaTypes.galleryVideoType === "youtube" && <a href="#!" className="article-videoLink" data-video={"https://www.youtube.com/embed/" + mediaTypes.galleryVideoId + "?autoplay=true"}>PLAY VIDEO</a>}
					</div>
				</div>}
				{mediaTypes.imageVideoDocumentCaption != null && <div className="article_video_caption"><span>Source: </span>{mediaTypes.imageVideoDocumentCaption}</div>}
			</div>}

			{mediaTypes.mediaType === "document" && <div className="article_gallery_sec">
				{data.post.frontline_articles_options.enableGallery != true && mediaTypes.imageVideoDocumentTitle !== null && <h4 className="article_gallery_title"><span>GALLERY: </span>{mediaTypes.imageVideoDocumentTitle}</h4>}
				{mediaTypes.documentPoster !== null && <div className="article_gallery_link_img">
					<img src={mediaTypes.documentPoster.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />
					{data.post.frontline_articles_options.enableGallery === true && galleryItemArr.length > 1 && <AniLink className="btn primary-btn" fade duration={2} to={"/" + data.post.uri + "/gallery"}>VIEW GALLERY</AniLink>}
				</div>}


				{galleryItemArr.length <= 1 && mediaTypes.imageVideoDocumentCaption != null && <div className="article_gallery_caption"><span>Source: </span>{mediaTypes.imageVideoDocumentCaption}</div>}
			</div>}
		</div>
	});

	// const articleMilestones = pageContext.explore_section === "enable" && pageContext.explore_milestones.map((explore_milestone) => {
	// let articleMilestonesLoop = [];
	const articleMilestones = [];
	data.post.frontline_articles_options.enableExploreSection && data.posts.edges.forEach((edge) => {
		if (edge.node.id !== data.post.id) {
			articleMilestones.push(<div key={edge.node.id}>
				<div className="explore-milestone-item">
					<div className="explore-milestone-img">
						<AniLink fade duration={2} to={"/" + edge.node.uri}>
							{edge.node.frontline_articles_options.backgroundImage !== null && <img src={edge.node.frontline_articles_options.backgroundImage.sourceUrl} alt={edge.node.title} />}
						</AniLink>
					</div>
					<h3 className="explore-milestone-item-title"><AniLink fade duration={2} to={"/" + edge.node.uri}>{edge.node.title}</AniLink></h3>
					{edge.node.frontline_articles_options.shortDescription !== null && <div dangerouslySetInnerHTML={{ __html: edge.node.frontline_articles_options.shortDescription }} />}
				</div>
			</div>)
		}
	});
	// console.log(articleMilestones)
	// let articleMilestonesLoop = [];
	// articleMilestones.forEach((value, key) => {
	//     if(value) { 
	//         articleMilestonesLoop.push(value)
	//     }
	// })
	// console.log(isGalleryOnBckImage);
	return (
		<Layout logoType={data.post.frontline_articles_options.backgroundColor}>
			<SEO title={data.post.title} />
			<Controller>
				<Scene
					duration={300}
				>
					{(progress) => (
						<>
							{data.post.frontline_articles_options.backgroundImage !== null && <Timeline totalProgress={progress} paused
								target={
									<div className="single-article-img" style={{ backgroundImage: 'url(' + data.post.frontline_articles_options.backgroundImage.sourceUrl + ')' }} alt={data.post.title} />
								}
							>
								<Tween
									from={{ opacity: 1 }}
									to={{ opacity: 0 }}
								/>
							</Timeline>}
							<div className="single-article single-article-cover-wrap">
								<div className={"single-article-cover " + data.post.frontline_articles_options.articlePageBackgroundColor + " " + data.post.frontline_articles_options.articlePageTextColor + " " + data.post.frontline_articles_options.articlePageGalleryTitleColor + " " + data.post.frontline_articles_options.articlePageTimelineColor}>
									<Container>
										<Row>
											<Col className="single-article-year-timeline" lg={{ span: 4 }} xl={{ span: 5 }}>
												<div className="article-timeline-verticle">
													<ArticleYearSlider currentyear={data.post.frontline_articles_options.articleYear} />
													<div className="single-article-timeline"></div>
												</div>
											</Col>
											<Col className={"single-article-wrap " + (data.post.frontline_articles_options.backgroundImage ? "" : "mt-150")} lg={{ span: 8, offset: 4 }} xl={{ span: 7, offset: 5 }}>
												<Timeline totalProgress={progress} paused
													target={
														<Row>
															<Col sm={{ span: 6, offset: 6 }}>
																{data.post.frontline_articles_options.shortDescription && <div className="single-article-short-desc" dangerouslySetInnerHTML={{ __html: data.post.frontline_articles_options.shortDescription }} />}
															</Col>
														</Row>
													}
												>
													<Tween
														from={{ opacity: 1 }}
														to={{ opacity: 0 }}
													/>
												</Timeline>
												{data.post.title && <h1 className="page-title" dangerouslySetInnerHTML={{ __html: data.post.title }} />}
												{data.post.frontline_articles_options.belowTitleShortDescription && <div className="article_below_title_description" dangerouslySetInnerHTML={{ __html: data.post.frontline_articles_options.belowTitleShortDescription }} />}
												<div dangerouslySetInnerHTML={{ __html: data.post.frontline_articles_options.fullDescriptionAboveGallery }} />

												{data.post.frontline_articles_options.setBackgroundImageAsFeaturedImage === true &&
													<div className="article_gallery_sec">
														{data.post.frontline_articles_options.backgroundImage !== null && <div className="article_gallery_link_img">
															<img src={data.post.frontline_articles_options.backgroundImage.sourceUrl} alt={data.post.title} />
															{data.post.frontline_articles_options.enableGallery === true && galleryItemArr.length > 0 && isGalleryOnBckImage === 1 && <AniLink className="btn primary-btn" fade duration={2} to={"/" + data.post.uri + "/gallery"}>VIEW GALLERY</AniLink>}
														</div>}
													</div>}

												{articleGallery}

												{data.post.frontline_articles_options.fullDescriptionBelowGallery && <div className="mt-5" dangerouslySetInnerHTML={{ __html: data.post.frontline_articles_options.fullDescriptionBelowGallery }} />}
											</Col>
										</Row>
									</Container>
								</div>
								{/* {data.post.explore_section === "enable" && <div className="explore-sec"> */}
								{data.post.frontline_articles_options.enableExploreSection && <div className={"explore-sec " + data.post.frontline_articles_options.exploreSectionBackgroundColor + " " + data.post.frontline_articles_options.exploreSectionTitleColor}>
									<h2 className="page-title text-center">Explore</h2>
									<div className="explore-slider">
										<Swiper {...params_explore}>
											{articleMilestones}
										</Swiper>
									</div>
								</div>}
							</div>
						</>
					)}
				</Scene>
			</Controller>
		</Layout>
	)
}

export default SingleArticle
